<template>
  <b-overlay :show="formShow" rounded="sm" no-fade>
    <app-collapse accordion type="margin">
      <app-collapse-item v-for="(item, index) in bankHolidays" :key="index" :title="capitalizeFirstLetter(item.division)">
        <b-table :items="item.holidays" small striped :fields="fields" selectable select-mode="single">
          <template #cell(date)="data">
            <strong>{{ formatDate(data.item.date) }}</strong>
          </template>
          <template #cell(title)="data">
            {{ data.item.title }}
          </template>

          <template #cell(action)="data">
            <!-- <b-button @click="editHoliday(data.item.id)" variant="secondary" size="sm" class="mr-50"><i class="fas fa-edit"></i></b-button> -->
            <b-button @click="deleteHoliday(data.item.id)" variant="danger" size="sm"><i class="fas fa-trash-alt"></i></b-button>
          </template>
        </b-table>
      </app-collapse-item>
    </app-collapse>
  </b-overlay>
</template>

<script>
import axiosIns from '@/libs/axios';
import { BTable } from 'bootstrap-vue';
import store from '@/store';
import settingsStoreModule from '../settingsStoreModule';
import { onUnmounted } from '@vue/composition-api';
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { BTabs, BTab, BCardText, BFormRadioGroup, BFormRadio, BFormGroup } from 'bootstrap-vue';
import vSelect from 'vue-select';
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue';
import { formatDate } from '@/views/helpers';

export default {
  components: {
    BTable,
    vSelect,

    ToastificationContent,
    BCardText,
    BTabs,
    BTab,
    AppCollapse,
    AppCollapseItem,
    BFormRadioGroup,
    BFormRadio,
    BFormGroup,
  },

  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = 'settings';

    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });

    const toast = useToast();
  },

  data() {
    return {
      expandedDivision: null,
      bankHolidays: [],
      fields: [
        { key: 'date', label: 'Date', thStyle: 'width: 40%' },
        { key: 'title', label: 'Title', thStyle: 'width: 55%' },
        { key: 'action', label: 'Action', thStyle: 'width: 5%' },
      ],

      formShow: false,
    };
  },
  computed: {},
  methods: {
    formatDate,
    getBankHolidays() {
      this.formShow = true;
      store
        .dispatch('settings/getBankHolidays', [])
        .then((res) => {
          this.bankHolidays = res.data;
          this.formShow = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    editHoliday(holiday) {
      console.log('Edit holiday:', holiday);
    },

    deleteHoliday(id) {
      console.log(id);
      this.$nextTick(() => {
        this.$bvModal
          .msgBoxConfirm('Are you sure you want to delete this holiday?', {
            size: 'sm',
            title: 'Confirm',
            okVariant: 'primary',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: true,
            centered: true,
          })
          .then((value) => {
            if (value) {
              this.loading = true;
              store
                .dispatch('settings/deleteBankHoliday', id)
                .then((res) => {
                  this.loading = false;
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Successful',
                      text: '✔️ Holiday has been deleted.',
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  });
                  this.getBankHolidays();
                })
                .catch((error) => {
                  console.log(error);
                  this.loading = false;
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'An error occurred',
                      text: 'Please try again later or contact support.',
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  });
                });
            }
          });
      });
    },

    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
  created() {
    this.getBankHolidays();
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
