// helpers.js

export function getTruncatedDescription(description) {
  return description.slice(0, 50) + '...';
}

export function formatDateTime(dateTimeString) {
  const dateTime = new Date(dateTimeString);
  const options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
  return dateTime.toLocaleDateString('en-UK', options);
}

export function hoursFormat(val) {
  var totalMinutes = Math.floor(val / 60);

  var hours = Math.floor(totalMinutes / 60);
  var minutes = totalMinutes % 60;

  return hours + ' Hours, ' + minutes + ' Minutes';
}

export function getTodayDate() {
  const today = new Date();
  const day = String(today.getDate()).padStart(2, '0');
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Note: January is 0!
  const year = today.getFullYear();
  const todayDate = `${day}-${month}-${year}`;
  return todayDate;
}

export function formatDate(value) {
  if (!value) return value;
  const date = new Date(value);
  const day = date
    .getDate()
    .toString()
    .padStart(2, '0');
  const month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(date);
  const year = date.getFullYear().toString();
  return `${day} ${month}  ${year}`;
}

export function getTeamClass(team) {
  switch (team) {
    case 'Systems Tailor Made':
      return 'text-primary';
    case 'Tailor Made Excel':
      return 'text-success';
    case 'Group Tailor Made':
      return 'text-warning';
    default:
      return 'text-secondary';
  }
}

export function convertToFirstISO(dateString) {
  const parts = dateString.split('-');
  const isoDate = new Date(Date.UTC(parts[2], parts[1] - 1, parts[0], 0, 0, 0)).toISOString();
  return isoDate;
}

export function convertToSecondISO(dateString) {
  const parts = dateString.split('-');
  const isoDate = new Date(Date.UTC(parts[2], parts[1] - 1, parts[0], 23, 59, 59)).toISOString();
  return isoDate;
}
